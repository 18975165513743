const modelQueryRoot = {
    storage_logdata_total: {model:44, query: 208, name: '原始日志与数仓汇总'},
    storage_algo_total: {model:44, query: 210, name: '算法泛相关汇总'},
    storage_word_media_total: {model:44, query: 213, name: '词表与素材分发汇总'},
    offline_calculation_total: {model:45, query: 248, name: '离线计算层汇总'},
    offline_calculation_realtime: {model:45, query: 242, name: '实时计算Flink'},
    application_total: {model:46, query: 250, name: '应用层汇总'},
    kafka_total: {model:47, query: 244, name: '应用层(Kafka)汇总'},
    collection_total: {model:48, query: 254, name: '采集层汇总'},
    storage_total: {model: 'storage', query: 0, name: '存储层汇总'},
    total: {model: 'total', query: 0, name: '汇总'},
}

export default modelQueryRoot