<template>
    <div class="common-content">
        <DiagramBar
        :btnLoading="tableLoading"
        :showDimension="false"
        :showGranularity="false"
        @onQuery="onQuery"
        @update:date="updateDate"
        @update:refDate="updateRefDate"
        @showTable="isCollectionTableShow = true"></DiagramBar>
        <n-spin :show="chartLoading">
            <div
                id="konva_app"
                :style="{ height: paperHeight + 'px' }"
                class="paper"
            ></div>
            <template #description> 数据读取中, 请稍后... </template>
        </n-spin>
        <!-- <DiagramTable v-model:show="isCollectionTableShow" 
        :tableData="tableData"
        :loading="tableLoading"
        :root="root"
        :useDefault="false"
        @update:show="isCollectionTableShow = false">
        </DiagramTable> -->

        <DiagramTableV2 v-model:show="isCollectionTableShow" 
        :useDefault="false"
        :tableData="tableData"
        :root="root"
        type="model"
        :start="start.toString()"
        :end="end.toString()"
        :loadRef="startRef !== '' && endRef !== ''"
        :startRef="startRef"
        :endRef="endRef"
        :curData="curData"
        @update:show="isCollectionTableShow = false">
        </DiagramTableV2>
    </div>
</template>
<style lang="less" scoped>
@import '../../../../common/common.less';
.paper {
    .standard-border;
    .standard-shadow;
    margin-top: 10px;
    background-color: #fff;
}
</style>
<script setup>
import { ref, onMounted, nextTick, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import DiagramBar from '@/views/Business/Analysis/components/DiagramBar.vue';
import DiagramTable from '@/views/Business/Analysis/components/DiagramTable.vue';
import DiagramTableV2 from '@/views/Business/Analysis/components/DiagramTableV2.vue';

import dayjs from 'dayjs';
import { NSpin } from 'naive-ui';
import KonvaApp from './Konva/Entry';
import modelquery from './ModelQuery'
import tableCollection from './TableColletion';
import modelQueryRoot from './ModelQueryRoot';

// import modelquery from './ModelQueryTest';
// import tableCollection from './TableCollectionTest';
// import modelQueryRoot from './ModelQueryRootTest';

import { CostNervAPI } from '@/common/API';
import { convertDataModel, convertDataModelWithoutRecursion } from '@/views/Business/Analysis/tools/tool.js';

const route = useRoute();

let isCollectionTableShow = ref(false);

let start = ref(new Date(route.query.start+' 00:00:00').getTime()/1000 || dayjs().subtract(8, 'day').startOf('day').valueOf() / 1000);
let end = ref(new Date(route.query.end+' 00:00:00').getTime()/1000 || dayjs().subtract(2, 'day').startOf('day').valueOf() / 1000);

let startRef = ref(new Date(route.query.startRef+' 00:00:00').getTime()/1000 || '');
let endRef = ref(new Date(route.query.endRef+' 00:00:00').getTime()/1000 || '');

let chartData = ref({});
let chartLoading = ref(false);

const designWidth = 1920;
const designHeight = 980;

let dom = null;
let app = null;

let paperHeight = ref(99);

let tableData = ref([]);
let tableLoading = ref(true);
let root = ref([]);
let curData = ref({});
let yoyData = ref({});
let preData = ref({});
let refData = ref({});

let curLoadingComplete = ref(false);
let yoyLoadingComplete = ref(false);
let preLoadingComplete = ref(false);
let refLoadingComplete = ref(true);

function computePreDate() {
    return [start.value - 1 * 24 * 60 * 60, end.value - 1 * 24 * 60 * 60]
}

function computeYoyDate() {
    return [start.value - 7 * 24 * 60 * 60, end.value - 7 * 24 * 60 * 60]
}

function onQuery() {
    loadMutiple()
    load(computeYoyDate()[0], computeYoyDate()[1], 'yoy')
    load(computePreDate()[0], computePreDate()[1], 'pre')

    if (startRef.value !== '' && endRef.value !== '') {
        load(startRef.value, endRef.value, 'ref')
    }
};
function updateDate(val) {
    start.value = new Date(val.start_time+' 00:00:00').getTime() / 1000;
    end.value = new Date(val.end_time+' 00:00:00').getTime() / 1000;
};

function updateRefDate(val) {
    refData.value = {}
    startRef.value = new Date(val.start_time+' 00:00:00').getTime() / 1000;
    endRef.value = new Date(val.end_time+' 00:00:00').getTime() / 1000;
};

function load(startVal, endVal, tag) {
    switch (tag) {
        case 'yoy':
            yoyData.value = {}
            yoyLoadingComplete.value = false;
            break;
        case 'pre':
            preData.value = {}
            preLoadingComplete.value = false;
            break;
        case 'ref':
            refData.value = {}
            refLoadingComplete.value = false;
            break;
        default:
            break;
    }    
    
    tableLoading.value = true
    let completedRequests = 0;
    for (const item in modelQueryRoot) {
        if (modelQueryRoot[item].model === 'total' || modelQueryRoot[item].model === 'storage') {
            continue;
        }
        CostNervAPI.calculateQuery(
            modelQueryRoot[item].model,
            modelQueryRoot[item].query,
            startVal,
            endVal,
        )
            .then(res => {
                let avg = 0.0;
                let sum = 0.0
                let end_date = (new Date('2000-01-01 00:00:00')).getTime() / 1000
                let end_value = 0.0
                
                const obj = Object.values(res.data.values)[0]
                const len = Object.keys(obj).length;
                
                for (const key in obj) {
                    sum += obj[key];
                    if (end_date < key) {
                        end_date = key
                    }
                }
                avg = (sum / len).toFixed(2);
                end_value = obj[end_date]

                if (tag === 'yoy') {
                    yoyData.value[item] = {
                        id: item,
                        name: modelQueryRoot[item].name,
                        model_id: modelQueryRoot[item].model,
                        query_id: modelQueryRoot[item].query,
                        value: parseFloat(end_value),
                    };
                } else if (tag === 'pre') {
                    preData.value[item] = {
                        id: item,
                        name: modelQueryRoot[item].name,
                        model_id: modelQueryRoot[item].model,
                        query_id: modelQueryRoot[item].query,
                        value: parseFloat(end_value),
                    };
                } else if (tag === 'ref') {
                    refData.value[item] = {
                        id: item,
                        name: modelQueryRoot[item].name,
                        model_id: modelQueryRoot[item].model,
                        query_id: modelQueryRoot[item].query,
                        value: parseFloat(avg),
                    };
                }
                completedRequests++;
                if (completedRequests === Object.keys(modelQueryRoot).length - 2) {
                    if (tag === 'yoy') {
                        let storage_end_value = yoyData.value['storage_logdata_total'].value + yoyData.value['storage_algo_total'].value + yoyData.value['storage_word_media_total'].value
                        yoyData.value['storage_total'] = { 
                            id: 'storage_total',
                            name: '存储层汇总',
                            model_id: 'storage',
                            query_id: 0,
                            value: storage_end_value,
                        };

                        // test
                        // let total_end_value = yoyData.value['storage_total'].value + yoyData.value['offline_calculation_total'].value
                        let total_end_value = yoyData.value['storage_total'].value + yoyData.value['offline_calculation_total'].value + yoyData.value['application_total'].value + yoyData.value['kafka_total'].value + yoyData.value['offline_calculation_realtime'].value
                        yoyData.value['total'] = { 
                            id: 'total',
                            name: '汇总',
                            model_id: 'total',
                            query_id: 0,
                            value: total_end_value,
                        };
                    } else if (tag === 'pre') {
                        let storage_end_value = preData.value['storage_logdata_total'].value + preData.value['storage_algo_total'].value + preData.value['storage_word_media_total'].value
                        preData.value['storage_total'] = { 
                            id: 'storage_total',
                            name: '存储层汇总',
                            model_id: 'storage',
                            query_id: 0,
                            value: storage_end_value,
                        };

                        // test
                        // let total_end_value = preData.value['storage_total'].value + preData.value['offline_calculation_total'].value
                        let total_end_value = preData.value['storage_total'].value + preData.value['offline_calculation_total'].value + preData.value['application_total'].value + preData.value['kafka_total'].value + preData.value['offline_calculation_realtime'].value
                        preData.value['total'] = { 
                            id: 'total',
                            name: '汇总',
                            model_id: 'total',
                            query_id: 0,
                            value: total_end_value,
                        };
                    } else if (tag === 'ref') {
                        let storage_end_value = refData.value['storage_logdata_total'].value + refData.value['storage_algo_total'].value + refData.value['storage_word_media_total'].value
                        refData.value['storage_total'] = { 
                            id: 'storage_total',
                            name: '存储层汇总',
                            model_id: 'storage',
                            query_id: 0,
                            value: storage_end_value.toFixed(2),
                        };

                        // test
                        // let total_end_value = refData.value['collection_request_total'].value + parseFloat(refData.value['storage_total'].value)
                        let total_end_value = parseFloat(refData.value['storage_total'].value) + refData.value['offline_calculation_total'].value + refData.value['application_total'].value + refData.value['kafka_total'].value + refData.value['offline_calculation_realtime'].value
                        refData.value['total'] = { 
                            id: 'total',
                            name: '汇总',
                            model_id: 'total',
                            query_id: 0,
                            value: parseFloat(total_end_value).toFixed(2),
                        };
                    }
                }
            })
            .catch(err => {
                console.log(err);
            });
    }
};

function loadMutiple() {
    chartLoading.value = true;
    curData.value = {}
    curLoadingComplete.value = false
    tableLoading.value = true
    let completedRequests = 0;

    for (const item in modelquery) {
        if (modelquery[item].model === 'total' || modelquery[item].model === 'storage') {
            continue;
        }
        CostNervAPI.calculateQuery(
            modelquery[item].model,
            modelquery[item].query,
            start.value,
            end.value,
        )
            .then(res => {
                let avg = 0.0;
                let sum = 0.0
                let end_date = (new Date('2000-01-01 00:00:00')).getTime() / 1000
                let end_value = 0.0
                
                const obj = Object.values(res.data.values)[0]
                const len = Object.keys(obj).length;
                
                for (const key in obj) {
                    sum += obj[key];
                    if (end_date < key) {
                        end_date = key
                    }
                }
                avg = (sum / len).toFixed(2);
                end_value = obj[end_date]
                
                chartData.value[item] = { value: avg };
                app.setData(chartData.value);

                curData.value[item] = { 
                    id: item,
                    name: modelquery[item].name,
                    model_id: modelquery[item].model,
                    query_id: modelquery[item].query,
                    value: parseFloat(end_value),
                    value_avg : parseFloat(avg),
                };

                completedRequests++;
                if (completedRequests === Object.keys(modelquery).length - 2) {
                    let storage_end_value = curData.value['storage_logdata_total'].value + curData.value['storage_algo_total'].value + curData.value['storage_word_media_total'].value
                    let storage_avg = curData.value['storage_logdata_total'].value_avg + curData.value['storage_algo_total'].value_avg + curData.value['storage_word_media_total'].value_avg
                    curData.value['storage_total'] = { 
                        id: 'storage_total',
                        name: '存储层汇总',
                        model_id: 'storage',
                        query_id: 0,
                        value: storage_end_value,
                        value_avg : parseFloat(storage_avg).toFixed(2),
                    };

                    let total_end_value = curData.value['storage_total'].value + curData.value['offline_calculation_total'].value + curData.value['application_total'].value + curData.value['kafka_total'].value + curData.value['offline_calculation_realtime'].value
                    let total_avg = parseFloat(curData.value['storage_total'].value_avg) + curData.value['offline_calculation_total'].value_avg + curData.value['application_total'].value_avg + curData.value['kafka_total'].value_avg + curData.value['offline_calculation_realtime'].value_avg

                    // test
                    // let total_end_value = curData.value['storage_total'].value + curData.value['offline_calculation_total'].value
                    // let total_avg = curData.value['storage_total'].value_avg + curData.value['offline_calculation_total'].value_avg
                    curData.value['total'] = { 
                        id: 'total',
                        name: '汇总',
                        model_id: 'total',
                        query_id: 0,
                        value: total_end_value,
                        value_avg : parseFloat(total_avg).toFixed(2),
                    };
                    chartData.value['storage_total'] = { value: parseFloat(storage_avg).toFixed(2) };
                    chartData.value['total'] = { value: parseFloat(total_avg).toFixed(2) };
                    app.setData(chartData.value);

                    chartLoading.value = false;
                }
            })
            .catch(err => {
                console.log(err);
                chartLoading.value = false;
            });
    }
}

function initKonvaApp() {
    if (app) {
        return;
    }
    dom = document.querySelector('#konva_app');
    paperHeight.value = (dom.clientWidth * designHeight) / designWidth;
    nextTick(() => {
        app = new KonvaApp('konva_app');
        // 当paper在小屏上渲染时，可能因高度不够产生滚动条，继而又影响了dom宽度，导致实际宽度和之前计算的不同，这是需要再计算一遍
        paperHeight.value = (dom.clientWidth * designHeight) / designWidth;
        app.setSize(dom.clientWidth, paperHeight.value);
        // 点击事件暂时关闭
        const basicURL = window.location.protocol + '//' + window.location.host;
        app.setClickHandler((name) => {
            let model_id = curData.value[name].model_id === 'storage' ? '44' : curData.value[name].model_id
            let link = basicURL + `/cost_nerv/model/${model_id}` + '?' + `start=${start.value * 1000}` + '&' + `end=${end.value * 1000}`;
            window.open(link, '_blank')
        });

        // test
        // const avg = 0.0
        // for (const item in modelquery) {
        //     chartData.value[item] = { value: avg };
        //     app.setData(chartData.value);
        // } 
    });
    window.addEventListener('resize', () => {
        paperHeight.value = (dom.clientWidth * designHeight) / designWidth;
        app.setSize(dom.clientWidth, paperHeight.value);
    });
}

onMounted(() => {
    root.value = Object.keys(tableCollection)
    initKonvaApp();
    loadMutiple();
    load(computeYoyDate()[0], computeYoyDate()[1], 'yoy') //同比
    load(computePreDate()[0], computePreDate()[1], 'pre') //环比

    if (startRef.value !== '' && endRef.value !== '') {
        load(startRef.value, endRef.value, 'ref')
    }
});

watch (() => curData.value,
    () => {
        if (Object.keys(curData.value).length === Object.keys(modelquery).length) {
            curLoadingComplete.value = true;
        }
    },
    { deep: true }
);

watch (() => yoyData.value,
    () => {
        if (Object.keys(yoyData.value).length === Object.keys(modelQueryRoot).length) {
            yoyLoadingComplete.value = true;
        }
    },
    { deep: true }
);

watch (() => preData.value,
    () => {
        if (Object.keys(preData.value).length === Object.keys(modelQueryRoot).length) {
            preLoadingComplete.value = true;
        }
    },
    { deep: true }
);

watch (() => refData.value,
    () => {
        if (Object.keys(refData.value).length === Object.keys(modelQueryRoot).length) {
            refLoadingComplete.value = true;
        }
    },
    { deep: true }
);

let oriDataLoadingComplete = computed(
    () => curLoadingComplete.value && yoyLoadingComplete.value && preLoadingComplete.value && refLoadingComplete.value
);

watch(() => oriDataLoadingComplete.value,
    (newVal, oldVal) => {
        if (newVal) {
            tableLoading.value = true
            tableData.value.splice(0, tableData.value.length)

            // tableData.value = convertDataModel(tableCollection, curData.value, yoyData.value, preData.value, refData.value);

            // root.value = Object.keys(tableCollection)
            
            tableData.value = convertDataModelWithoutRecursion(tableCollection, root.value, curData.value, yoyData.value, preData.value, refData.value, 0);
            
            tableLoading.value = false
        }
    },
)
</script>
