/**
 * @file Tools
 */

// 设计稿标准宽度
const baseWidth = 1920;

// 设计稿标准高度
const baseHeight = 980;

export function sizeTool(stageW, stageH) {
    return {
        size(v) {
            return (stageW * v) / baseWidth;
        },
        sizeByH(v) {
            return (stageH * v) / baseHeight;
        },
    };
}
