/**
 * @file 数据标签层
 */

import Konva from 'konva';
import { sizeTool } from './Tools';

export default function draw(stage) {
    // 此处引入画布dom，用来控制标签hover时的鼠标样式
    let container = stage.container();
    const { size } = sizeTool(stage.width(), stage.height());

    let layer = new Konva.Layer();

    let state = {};

    const labelFontSize = size(16);

    function onLabelMouseEnter(e) {
        container.style.cursor = 'pointer';
        let key = e.target.name();
        if (!state[key]) {
            return;
        }
        e.target.textDecoration('underline');
    }

    function update() {
        for (let key in state) {
            let v = state[key];
            if (!components[key]) {
                continue;
            }
            let labelText = components[key].getText();
            if (v.value.toString() !== labelText.text()) {
                labelText.text(v.value);
            }
        }
    }

    function createLabelInstance(
        x,
        y,
        transparent = true,
        fontSize = labelFontSize
    ) {
        let label = new Konva.Label({
            x: size(x),
            y: size(y),
        });
        if (!transparent) {
            label.add(
                new Konva.Tag({
                    fill: '#fff',
                })
            );
        }
        let text = new Konva.Text({
            x: 0,
            y: 0,
            text: '',
            fontSize,
        });
        label.add(text);
        return label;
    }

    function createClickableLabelInstance(
        x,
        y,
        transparent = true,
        fontSize = labelFontSize
    ) {
        let label = new Konva.Label({
            x: size(x),
            y: size(y),
        });
        if (!transparent) {
            label.add(
                new Konva.Tag({
                    fill: '#fff',
                })
            );
        }
        let text = new Konva.Text({
            x: 0,
            y: 0,
            text: '',
            fontSize,
        });
        label.add(text);

        text.on('mouseenter', onLabelMouseEnter);
        text.on('mouseout', e => {
            container.style.cursor = 'default';
            e.target.textDecoration('');
        });
        text.on('click', () => {
            labelClickCallback && labelClickCallback(text.name());
        });
        return label;
    }

    let components = {
        collection_total: createClickableLabelInstance(214,110),
        collection_request_total: createLabelInstance(170,184),
        collection_request_frontier: createLabelInstance(64,242),
        collection_request_ssp: createLabelInstance(236,242),
        collection_request_madx: createLabelInstance(64,294),
        collection_request_dsp: createLabelInstance(236,294),
        collection_request_analytics: createLabelInstance(64,724),
        collection_request_tracking: createLabelInstance(64,513),
        storage_logdata_total: createLabelInstance(631,184),
        storage_logdata_log_total: createLabelInstance(612,217),
        storage_logdata_log_oss: createLabelInstance(612,244),
        storage_logdata_log_s3: createLabelInstance(612,270),
        storage_logdata_data_total: createLabelInstance(835,217),
        storage_logdata_data_oss: createLabelInstance(835,244),
        storage_logdata_data_s3: createLabelInstance(835,270),
        storage_logdata_rta: createLabelInstance(612,298),
        storage_logdata_elb: createLabelInstance(835,298),
        storage_algo_total: createLabelInstance(612,369),
        storage_algo_s3bucket: createLabelInstance(612,399),
        storage_algo_public: createLabelInstance(845,399),
        storage_algo_midplatform: createLabelInstance(612,454),
        storage_algo_midplatform_oss: createLabelInstance(835,454),
        storage_algo_midplatform_s3: createLabelInstance(835,482),
        storage_word_media_total: createLabelInstance(631,555),
        storage_media_total: createLabelInstance(655,593),
        storage_media_pl: createLabelInstance(655,621),
        storage_media_image: createLabelInstance(519,672),
        storage_word_total: createLabelInstance(835,593),
        storage_word_oss: createLabelInstance(835,622),
        storage_word_s3: createLabelInstance(740,672),
        offline_calculation_total: createClickableLabelInstance(1177,110),
        offline_calculation_algo_total: createLabelInstance(1155,184),
        offline_calculation_algo_model: createLabelInstance(1066,244),
        offline_calculation_algo_midplatform: createLabelInstance(1066,297),
        offline_calculation_algo_num: createLabelInstance(1244,244),
        offline_calculation_algo_emr: createLabelInstance(1244,297),
        offline_calculation_engineering_total: createLabelInstance(1155,369),
        offline_calculation_engineering_data_vg: createLabelInstance(1066,431),
        offline_calculation_engineering_data_cn: createLabelInstance(1066,481),
        offline_calculation_engineering_statement: createLabelInstance(1244,431),
        offline_calculation_engineering_monitor: createLabelInstance(1244,481),
        offline_calculation_public_support_total: createLabelInstance(1155,556),
        offline_calculation_public_support_zeppelin: createLabelInstance(1066,613),
        offline_calculation_public_support_azkaban: createLabelInstance(1244,613),
        offline_calculation_public_support_sourcedb: createLabelInstance(1066,665),
        offline_calculation_public_support_publicnum: createLabelInstance(1244,665),
        offline_calculation_public_support_offline: createLabelInstance(1066,715),
        offline_calculation_realtime: createLabelInstance(1230,870),
        application_total: createClickableLabelInstance(1667,110),
        application_offline_statement_total: createLabelInstance(1651,185),
        application_offline_statement_doris: createLabelInstance(1521,255),
        application_offline_statement_redshift: createLabelInstance(1680,255),
        application_monitor_total: createLabelInstance(1651,390),
        application_monitor_moss: createLabelInstance(1521,460),
        application_monitor_starrocks: createLabelInstance(1680,460),
        kafka_total: createClickableLabelInstance(692,767),
        kafka_log_total: createLabelInstance(612,809),
        kafka_log_mix: createLabelInstance(612,851),
        kafka_log_hv: createLabelInstance(612,880),
        kafka_log_cn: createLabelInstance(612,909),
        kafka_log_feature: createLabelInstance(856,851),
        total: createLabelInstance(260,38),
        storage_total: createClickableLabelInstance(692,110),
    };

    for (let key in components) {
        let label = components[key];
        label.getText().name(key);
        layer.add(label);
    }

    stage.add(layer);

    let labelClickCallback = null;

    return {
        layer,
        setData(data) {
            state = data;
            // for (let key in components) {
            //     components[key].getText().text('');
            // }
            update();
        },
        onLabelClick(listener) {
            labelClickCallback = listener;
        },
    };
}
